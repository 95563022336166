import { FC } from 'react';
import router from 'next/router';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { Button } from '@emochan-cabinet/ui';
import { useIntl } from 'react-intl';
import { useAuthContext } from '~/hooks/useAuth';
import LogoutModal from '~/components/Logout';

const WSListNav: FC = () => {
  const { signout } = useAuthContext();
  const { formatMessage } = useIntl();
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <>
      <Flex justifyContent="center" alignItems="center" wrap="wrap" gap="16">
        <Button
          size="xs"
          color="primary"
          buttonStyle="ghost"
          as="a"
          href={formatMessage({ id: 'navigation.terms.url' })}
          target="_blank"
          rel="noopener noreferrer"
          label={formatMessage({ id: 'navigation.terms' })}
        />
        <Button
          size="xs"
          color="primary"
          buttonStyle="ghost"
          as="a"
          href={formatMessage({ id: 'navigation.privacypolicy.url' })}
          target="_blank"
          rel="noopener noreferrer"
          label={formatMessage({ id: 'navigation.privacypolicy' })}
        />
        <Button
          size="xs"
          color="primary"
          buttonStyle="ghost"
          as="a"
          href={formatMessage({ id: 'navigation.help.url' })}
          target="_blank"
          rel="noopener noreferrer"
          label={formatMessage({ id: 'navigation.help' })}
        />
        <Button
          size="xs"
          color="primary"
          buttonStyle="ghost"
          label={formatMessage({ id: 'navigation.logout' })}
          onClick={onOpen}
        />
      </Flex>
      <LogoutModal
        isOpen={isOpen}
        onClose={onClose}
        signout={() => {
          signout();
          router.reload();
        }}
      />
    </>
  );
};

export default WSListNav;
