import { FC } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  ModalProvider,
  useMedia,
} from '@emochan-cabinet/ui';

type LogoutModalProps = {
  isOpen: boolean
  signout(): void
  onClose(): void
}

const LogoutModal: FC<LogoutModalProps> = ({
  isOpen,
  signout,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const { isMedium } = useMedia();

  return (
    <ModalProvider isOpen={isOpen} onClose={onClose}>
      <ModalProvider.Header title={formatMessage({ id: 'auth.logout.heading' })} />
      <ModalProvider.Body>
        <ModalProvider.Description text={formatMessage({ id: 'auth.logout.message' })} />
      </ModalProvider.Body>
      <ModalProvider.Footer
        direction="horizontal"
        full={isMedium}
      >
        <Button
          key="cancel"
          label={formatMessage({ id: 'auth.button.label.logout.cancel' })}
          color="primary"
          buttonStyle="tonal"
          full
          size={isMedium ? 'large' : 'medium'}
          onClick={onClose}
        />
        <Button
          key="logout"
          label={formatMessage({ id: 'auth.button.label.logout' })}
          color="negative"
          full
          size={isMedium ? 'large' : 'medium'}
          onClick={signout}
        />
      </ModalProvider.Footer>
    </ModalProvider>
  );
};

export default LogoutModal;
