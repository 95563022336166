import Link from 'next/link';
import { gql } from 'urql';
import { useIntl } from 'react-intl';
import { Avatar, Button, List } from '@emochan-cabinet/ui';
import { FCWithFragment } from '~/helpers/types';
import { Workspace } from '~/generated/graphql';
import WSListMemberName from './WSListMemberName';

const WSListItem: FCWithFragment<Workspace & { divider: boolean }> = ({
  id,
  logoURL,
  name,
  member,
  divider = false,
}) => {
  const { formatMessage } = useIntl();

  return (
    <List.Item>
      <List.Item.Image>
        <Avatar imageURL={logoURL} name={name} kind="workspace" />
      </List.Item.Image>
      <List.Item.Content
        title={name}
        subtitle={<WSListMemberName {...member} />}
        divider={divider}
        action={(
          <Link passHref key={id} href={`/ws/${id}`} legacyBehavior>
            <Button
              as="a"
              label={formatMessage({ id: 'wslist.button.label' })}
              shape="pill"
              color="accent"
              size="small"
            />
          </Link>
        )}
      />
    </List.Item>
  );
};

WSListItem.fragment = gql`
  ${WSListMemberName.fragment}

  fragment WorkspaceFragment_WSListItem on Workspace {
    id
    name
    logoURL
    member {
      ...MemberFragment_WSListMemberName
    }
  }
`;

export default WSListItem;
