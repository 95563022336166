import { gql } from 'urql';
import { FormattedMessage } from 'react-intl';
import { Box } from '@chakra-ui/react';
import { FCWithFragment } from '~/helpers/types';
import { Member } from '~/generated/graphql';

const WSListMemberName: FCWithFragment<Member> = ({ name }) => (
  <Box color="grey.60">
    <FormattedMessage id="wslist.participating" values={{ name }} />
  </Box>
);

WSListMemberName.fragment = gql`
  fragment MemberFragment_WSListMemberName on Member {
    name
  }
`;

export default WSListMemberName;
