import { FC } from 'react';
import Head from 'next/head';
import { gql, useQuery } from 'urql';
import { useIntl } from 'react-intl';
import { Box, Container } from '@chakra-ui/react';
import {
  Header,
  EmochanSpinner,
  AppCell,
  Logo,
  List,
  useMedia,
} from '@emochan-cabinet/ui';
import ErrorPage from '~/pages/_error';
import { GetWsListDocument, Workspace } from '~/generated/graphql';
import WSListNav from './WSListNav';
import WSListItem from './WSListItem';

export const WSListQuery = gql`
  ${WSListItem.fragment}

  query GetWSList {
    workspaces {
      ...WorkspaceFragment_WSListItem
    }

    viewer {
      email
    }
  }
`;

const WSList: FC = () => {
  const { isLarge } = useMedia();
  const { formatMessage } = useIntl();

  const [{ data, error, fetching }] = useQuery({ query: GetWsListDocument });

  if (error) {
    return <ErrorPage statusCode={404} shouldForceSignOut />;
  }

  if (fetching) {
    return (
      <Box h="100vh">
        <EmochanSpinner />
      </Box>
    );
  }

  return (
    <AppCell as="main" bg="grey.5" flexDirection="column">
      <Head>
        <meta name="description" content={formatMessage({ id: 'app.description.member' })} />
        <meta property="og:description" content={formatMessage({ id: 'app.description.member' })} />
      </Head>
      <AppCell.Item flexGrow={0}>
        <Header start={{ label: 'emochan logo', logo: <Logo kind="emochanFull" size="xxs" /> }} />
      </AppCell.Item>
      <AppCell.Item flexGrow={1}>
        <Container maxW="container.medium">
          <Box pt={isLarge ? '48' : '24'} pb="16">
            <Box bg="grey.0" borderRadius="16" overflow="hidden">
              <List>
                <List.Header
                  title={formatMessage({ id: 'wslist.heading' })}
                  description={data?.viewer.email}
                />
                <List.Body>
                  {data && data.workspaces.map((w, i) => (
                    <WSListItem
                      key={w.id}
                      divider={i !== 0}
                      {...w as Workspace}
                    />
                  ))}
                </List.Body>
              </List>
            </Box>
          </Box>

          <WSListNav />
        </Container>
      </AppCell.Item>
    </AppCell>
  );
};

export default WSList;
