import { FC } from 'react';
import { useAuthContext } from '~/hooks/useAuth';
import AuthSkin from '~/components/AuthSkin';
import AppSkelton from '~/components/AppSkelton';
import WSList from './WSList';

export const WSListContainer: FC = () => {
  const { currentUser } = useAuthContext();

  return (
    <AppSkelton>
      <AuthSkin>
        {currentUser.requested && <WSList />}
      </AuthSkin>
    </AppSkelton>
  );
};
